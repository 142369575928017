[tabStatsAppearance] {
    .mat-tab-header {
        .mat-tab-label-container {
            .mat-tab-list {
                .mat-tab-label {
                    @apply p-0 flex flex-row justify-center items-center;

                    &:nth-child(-n+2) {
                        @apply w-[25%];
                    }

                    &:nth-child(3) {
                        @apply w-1/2 border-l border-dashed border-gray-400 bg-tw-success bg-opacity-10;
                    }



                }

            }
        }
    }
}

[tabRequestAppearance] {
    .mat-tab-header {
        .mat-tab-label-container {
            .mat-tab-list {
                @apply mb-3;

                .mat-tab-labels{
                    @apply flex gap-3;

                    .mat-tab-label {
                        @apply p-0 flex flex-row justify-center items-center w-1/2 border-1 border-gray-400 rounded-lg;
    
                        &.mat-tab-label-active, &.mat-tab-label-focused{
                            @apply bg-tw-primary text-white border-tw-primary opacity-100;
                        }
    
                        &:last-child {
                            @apply mr-0;
                        }
    
    
                    }
                }       

                .mat-ink-bar {
                    @apply hidden;
                }

            }
        }
    }
}

[tabAppearance] {
    .mat-tab-header {
        .mat-tab-label-container {
            .mat-tab-list {
                .mat-tab-label {
                    @apply p-0 flex flex-row justify-center items-center;

                    .mat-tab-label-content {
                        @apply p-0;
                    }


                }

                .mat-ink-bar {
                    @apply flex bg-tw-success w-1/2;
                }
            }
        }
    }
}

[livecoAppearance] {
    .mat-form-field-wrapper {
        height: 3em;
        padding: 5px !important; // Custom padding
        border: none; // Custom border color
        border-radius: 8px; // Custom border radius
        background-color: $white; // Custom background color

        .mat-form-field-flex {
            @apply p-0 w-full h-full flex flex-row justify-start items-center;
        }

        .mat-form-field-infix {
            @apply p-0 w-full h-fit border-0;

            .mat-form-field-label-wrapper {
                @apply pt-0 h-[200%] flex flex-row justify-start items-center overflow-visible;

                label {
                    @apply mt-[-0.5em] mb-0;
                }
            }
        }

        .mat-form-field-label {
            color: #3f51b5; // Custom label color
            font-weight: bold; // Custom label font weight
        }

        .mat-form-field-underline {
            display: none; // Hide the underline
        }

        .mat-form-field-subscript-wrapper {
            margin-top: 0; // Adjust margin for the subscript wrapper
            color: #f44336; // Custom color for the error messages
        }
    }

    .mat-input-element {
        padding: 0; // Adjust padding for the input element
        border: none; // Remove default input border
        background-color: transparent; // Transparent background for the input
        font-size: 16px; // Custom font size
        color: #000; // Custom text color
    }

    .mat-hint,
    .mat-error {
        font-size: 12px; // Custom hint and error font size
    }

}

.mat-error {
    @apply mt-3;

}

.label-grey {
    .mat-form-field-label {
        color: #9e9e9e !important; // Custom label color
    }
}

mat-select {
    .mat-select-trigger {
        @apply w-full;
    }

    .mat-select-arrow-wrapper {

        .mat-select-arrow {
            @apply hidden;

        }
    }
}

.mat-form-field.mat-form-field-type-mat-input.mat-form-field-appearance-outline,
.mat-form-field.mat-form-field-type-mat-select.mat-form-field-appearance-outline,
.mat-form-field.mat-form-field-type-mat-chip-list.mat-form-field-appearance-outline {
    @apply flex flex-col items-center h-auto justify-center;

    .mat-form-field-wrapper {
        @apply w-full h-auto block pb-0;
    }

    .mat-form-field-label-wrapper {
        @apply flex flex-row justify-start items-center;

        .mat-form-field-label {
            @apply flex gap-1 #{!important};

            &::before {
                content: "" !important;
                @apply w-[0.75rem] h-[0.75rem] bg-tw-dark-primary mt-[0.15rem] #{!important};
            }
        }
    }

    // État standard (valide et/ou non touché)
    &:not(.ng-invalid),
    &.ng-untouched {
        .mat-form-field-outline {

            .mat-form-field-outline-start,
            .mat-form-field-outline-gap,
            .mat-form-field-outline-end {
                border-color: var(--tw-primary);
            }
        }

        .mat-form-field-label {
            @apply text-tw-body-color #{!important};
        }
    }

    // État focus
    &:not(.ng-invalid).mat-focused {
        .mat-form-field-outline {

            .mat-form-field-outline-start,
            .mat-form-field-outline-gap,
            .mat-form-field-outline-end {
                border-color: var(--tw-dark-primary);
            }
        }
    }

    // État invalide
    &:not(.ng-untouched).ng-invalid {
        .mat-form-field-outline {

            .mat-form-field-outline-start,
            .mat-form-field-outline-gap,
            .mat-form-field-outline-end {
                border-color: var(--tw-error);
            }
        }
    }

    &.mat-form-field-has-label.mat-form-field-can-float.mat-form-field-should-float {
        .mat-form-field-label-wrapper {
            @apply left-[-0.65rem];
            transition: left 0.4s;

            .mat-form-field-label {
                transform: translateY(-1.65em) scale(0.75);
                transition: transform 0.4s;
            }
        }

        .mat-form-field-outline {
            .mat-form-field-outline-gap {
                border-top-color: transparent;
            }

            .mat-form-field-outline-start {
                @apply flex flex-col border-none;

                &::before {
                    content: "";
                    @apply h-[0.9rem] border-transparent;
                }

                &::after {
                    content: "";
                    @apply w-full grow border-l-[1px] border-b-[1px] rounded-bl-[5px];
                }
            }

            &.mat-form-field-outline-thick {
                .mat-form-field-outline-start {
                    &::after {
                        @apply border-l-[2px] border-b-[2px];
                    }
                }
            }
        }

        // Couleur selon état pour les bordures flottantes
        &:not(.ng-invalid).ng-valid,
        &:not(.ng-invalid).ng-untouched,
        &:not(.ng-invalid).ng-touched,
        &.ng-pristine {
            .mat-form-field-outline {
                .mat-form-field-outline-start {
                    &::after {
                        border-color: var(--tw-primary);
                    }
                }
            }

            &:not(.ng-invalid).mat-focused {
                .mat-form-field-outline {
                    .mat-form-field-outline-start {
                        &::after {
                            border-color: var(--tw-dark-primary);
                        }
                    }
                }
            }
        }

        // État invalide
        &:not(.ng-untouched).ng-invalid {
            .mat-form-field-outline {
                .mat-form-field-outline-start {
                    &::after {
                        border-color: var(--tw-error) !important;
                    }
                }
            }
        }
    }
}


.mat-paginator-range-label {
    margin: 0 20px 0 0 !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
    border-color: var(--tw-primary);
}

.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: var(--tw-primary);
}

.mat-slide-toggle.mat-accent.mat-checked .mat-slide-toggle-bar {
    background-color: var(--tw-primary);

    .mat-slide-toggle-thumb {
        background-color: var(--tw-primary);
        filter: brightness(1.15);
    }
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-background,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-background {
    background-color: var(--tw-primary);
}