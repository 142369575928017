.logo {
    cursor: pointer;
}

// .container {
//     max-width: $container-max-width;
//     padding-top: $paddingApp;
//     padding-left: $paddingApp;
//     padding-right: $paddingApp;
//     margin: auto;
//     color: $black;
// }

// .title {
//     color: $accent;
// }

// .btn {
//     margin-right: 5px !important;
//     padding-left: 8px !important;

//     .icon {
//         font-size: 2em;
//     }

// }

// .btn-valid {
//     border: 1px solid $green !important;

//     .mat-button-wrapper {
//         color: $green;
//     }

//     &:hover {
//         background: $green;

//         .mat-button-wrapper {
//             color: $white;
//         }
//     }
// }

// .btn-reject,
// .btn-delete {
//     border: 1px solid $red !important;

//     .mat-button-wrapper {
//         color: $red;
//     }

//     &:hover {
//         background: $red;

//         .mat-button-wrapper {
//             color: $white;
//         }
//     }
// }

// .btn-primary {
//     border: 1px solid $dark-blue !important;

//     .mat-button-wrapper {
//         color: $dark-blue;
//     }

//     &:hover {
//         background: $dark-blue;

//         .mat-button-wrapper {
//             color: $white;
//         }
//     }
// }



// .btn-cancel {
//     border: 1px solid $grey !important;

//     .mat-button-wrapper {
//         color: $grey;
//     }

//     &:hover {
//         background: $grey;

//         .mat-button-wrapper {
//             color: $white;
//         }
//     }
// }

.btn .mat-button-wrapper .icon {
    font-size: 2em;
}

.no-arrow .mat-expansion-indicator {
    display: none !important;
}

button.mat-button {
    padding-left: 16px !important;
    padding-right: 16px !important;
}

.version-chip:not(.selected) {
    @apply whitespace-nowrap cursor-pointer min-h-fit h-fit px-2 py-0 bg-tw-disabled text-tw-darkblue hover:bg-tw-darkblue hover:text-tw-lightgrey #{!important};
}

.version-chip.selected {
    @apply whitespace-nowrap cursor-pointer min-h-fit h-fit px-2 py-0 bg-tw-blue text-tw-lightgrey #{!important};
}


.color-box {

    @apply flex gap-1 items-start;

    &::before {
        @apply size-3 bg-tw-dark-primary mt-1;
        content: '';
        display: block;
    }
}

.project {

 
    .mat-input-element,
    .mat-select-value {
        @apply text-tw-primary #{!important};


        &:disabled {
            @apply text-tw-text-dark #{!important};
        }
    }

    .mat-select-disabled {
        .mat-select-value {
            @apply text-tw-text-dark #{!important};
        }
    }



}

.single-tooltip {
    word-break: break-all !important;
    white-space: normal !important;
}