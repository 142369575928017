$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

// Fonts
:root {
  --font-family-sans-serif: 'Gordita Regular', -apple-system, system-ui,
    BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, sans-serif;
  --font-family-base: $font-family-sans-serif;
  --tw-primary: #709A9D;
  --tw-dark-primary: #04636a;
  --tw-secondary: #DE5F4D;
  --tw-complementary: #fef7ea;
  --tw-disabled: #E3E3E3;
  --tw-lightgrey: #F5F5F5;
  --tw-text-disabled: #555555;
  --tw-success: #A5D256;
  --tw-error: #e35d54;
  --tw-text-light: #FFFFFF;
  --tw-text-dark: #000000;
  --tw-blue: #3BBFE8;
  --tw-darkblue: #2098BE;
  --tw-mediumgrey: #878787;
  --tw-body-color: #575c62;

}

// Spacing
$container-max-width: 1400px;
$borderContainerSpacing: 50px;
$paddingApp: 30px;

@import 'custom';