/* You can add global styles to this file, and also import other style files */
// Base
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

@import './theme/variables';
@import './theme/general';
@import './theme/btn';
@import './theme/base';
@import './theme/forms';
@import './theme/icons';
@import './theme/iconsEd';
@import './theme/main';
@import './theme/materialOverride';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./theme/states";
@import "./theme/states.icons";

@import '~@angular/material/theming';

// Import the default Angular Material theme
@include mat-core();

// Define your custom typography
$custom-typography: mat-typography-config($font-family: "'Segoe UI', Roboto, sans-serif",
                $headline: mat-typography-level(32px, 48px, 700),
                $title: mat-typography-level(24px, 36px, 400),
                $subheading-1: mat-typography-level(16px, 28px, 400),
                $subheading-2: mat-typography-level(14px, 24px, 500),
                $body-1: mat-typography-level(14px, 20px, 400),
                $body-2: mat-typography-level(14px, 24px, 500),
                $caption: mat-typography-level(12px, 20px, 400),
                $button: mat-typography-level(14px, 14px, 500));

// Apply your custom typography
@include angular-material-typography($custom-typography);


/* Scrollbar styles */
::-webkit-scrollbar {
        width: 0.625rem;
        height: 0.625rem;
}

::-webkit-scrollbar-track {
        background: #f5f5f5;
        border-radius: 0.625rem;
}

::-webkit-scrollbar-thumb {
        border-radius: 0.625rem;
        background: #ccc;
}

::-webkit-scrollbar-thumb:hover {
        background: #999;
}