// TO KEEP

// EXTERN

.icon-accept::before,
.icon-approve::before,
.icon-approved::before,
.icon-accepted::before {
    content: "check";
    /* Name of the icon in Google Fonts */
    font-family: "Material Icons";
}

.icon-delivered::before {
    content: "local_shipping";
    /* Name of the icon in Google Fonts */
    font-family: "Material Icons";
}

.icon-post-validation::before {
    content: "restart_alt";
    /* Name of the icon in Google Fonts */
    font-family: "Material Icons";
}

.icon-rejected::before,
.icon-reject::before {
    content: "close";
    /* Name of the icon in Google Fonts */
    font-family: "Material Icons";
}

.icon-paused::before {
    content: "pause";
    /* Name of the icon in Google Fonts */
    font-family: "Material Icons";
}

.icon-restart::before {
    content: "play_arrow";
    /* Name of the icon in Google Fonts */
    font-family: "Material Icons";
}

.icon-in-production::before {
    content: "conveyor_belt";
    /* Name of the icon in Google Fonts */
    font-family: "Material Icons";
}

.icon-send-pr-client::before,
.icon-send-pr-cpp::before {
    content: "send";
    /* Name of the icon in Google Fonts */
    font-family: "Material Icons";
}


// INTERN

.icon-to-deliver::before, .icon-re-deliver::before {
    content: "local_shipping";
    font-family: "Material Icons";
}

.icon-wip::before {
    content: "conveyor_belt";
    font-family: "Material Icons";
}

.icon-check-auto-control::before {
    content: "task";
    font-family: "Material Icons";
}

.icon-ready::before {
    content: "start";
    font-family: "Material Icons";
}

.icon-check-prod::before,
.icon-check-repro::before,
.icon-check-quality::before,
.icon-check-cpp::before {
    content: 'manage_search';
    font-family: "Material Icons";
}

.icon-control-validated::before,
.icon-intern-finished::before,
.icon-intern-finished-operator::before,
.icon-control-delivery-validated::before {
    content: 'check';
    font-family: "Material Icons";
}

.icon-cpp-intern-rejected::before,
.icon-prod-intern-rejected::before,
.icon-control-failed::before,
.icon-control-delivery-failed::before {
    content: 'close';
    font-family: "Material Icons";
}

.icon-pr-client-inProgress::before {
    content: "send";
    font-family: "Material Icons";
}

.icon-init::before {
    content: "hourglass_empty";
    font-family: "Material Icons";
}

.icon-in-progress::before {
    content: "approval";
    font-family: "Material Icons";
}

// EZD Request-Types

.icon-ezd-request-type-none::before {
    content: "help";
    font-family: "Material Icons";
}

.icon-ezd-request-type-visuals-isolation::before {
    content: "image_search";
    font-family: "Material Icons";
}

.icon-ezd-request-type-pdf-repository::before {
    content: "picture_as_pdf";
    font-family: "Material Icons";
}

.icon-ezd-request-type-text-correction::before {
    content: "edit_note";
    font-family: "Material Icons";
}

.icon-ezd-request-type-plan-correction::before {
    content: "edit_note";
    font-family: "Material Icons";
}

.icon-ezd-request-type-text-plan-correction::before {
    content: "edit_note";
    font-family: "Material Icons";
}

.icon-ezd-request-type-file-redelivery::before {
    content: "local_shipping";
    font-family: "Material Icons";
}

.icon-ezd-request-type-new-epson::before {
    content: "print";
    font-family: "Material Icons";
}

.icon-ezd-request-type-packshot::before {
    content: "photo_camera";
    font-family: "Material Icons";
}

.icon-ezd-request-type-hero-image::before {
    content: "center_focus_strong";
    font-family: "Material Icons";
}

.icon-ezd-request-type-new-account::before {
    content: "help";
    font-family: "Material Icons";
}

.icon-ezd-request-type-briefing::before {
    content: "assignment";
    font-family: "Material Icons";
}