// *:focus {
//     outline: none !important;
// }

// body {
//     color: #575c62;
//     padding: 0;
//     margin: 0px;
// }

// .d-flex {
//     display: flex !important;
// }

// .flex-row {
//     flex-direction: row !important;
// }

// .flex-column {
//     flex-direction: column !important;
// }

// .h-100 {
//     height: 100% !important;
// }

// .w-20 {
//     width: 20% !important;
// }

// .w-30 {
//     width: 30% !important;
// }

// .w-40 {
//     width: 40% !important;
// }

// .w-45 {
//     width: 45% !important;
// }

// .w-50 {
//     width: 50% !important;
// }

// .w-60 {
//     width: 60% !important;
// }

// .w-70 {
//     width: 70% !important;
// }

// .w-75 {
//     width: 75% !important;
// }

// .w-80 {
//     width: 80% !important;
// }

// .w-100 {
//     width: 100% !important;
// }

// .w-25 {
//     width: 25% !important;
// }

// .flex-nowrap {
//     flex-wrap: nowrap !important;
// }

// .m-0 {
//     margin: 0 !important;
// }

// .mt-0,
// .my-0 {
//     margin-top: 0 !important;
// }

// .mr-0,
// .mx-0 {
//     margin-right: 0 !important;
// }

// .mb-0,
// .my-0 {
//     margin-bottom: 0 !important;
// }

// .ml-0,
// .mx-0 {
//     margin-left: 0 !important;
// }

// .m-1 {
//     margin: 0.25rem !important;
// }

// .mt-1,
// .my-1 {
//     margin-top: 0.25rem !important;
// }

// .mr-1,
// .mx-1 {
//     margin-right: 0.25rem !important;
// }

// .mb-1,
// .my-1 {
//     margin-bottom: 0.25rem !important;
// }

// .ml-1,
// .mx-1 {
//     margin-left: 0.25rem !important;
// }

// .m-2 {
//     margin: 0.5rem !important;
// }

// .mt-2,
// .my-2 {
//     margin-top: 0.5rem !important;
// }

// .mr-2,
// .mx-2 {
//     margin-right: 0.5rem !important;
// }

// .mb-2,
// .my-2 {
//     margin-bottom: 0.5rem !important;
// }

// .ml-2,
// .mx-2 {
//     margin-left: 0.5rem !important;
// }

// .m-3 {
//     margin: 1rem !important;
// }

// .mt-3,
// .my-3 {
//     margin-top: 1rem !important;
// }

// .mr-3,
// .mx-3 {
//     margin-right: 1rem !important;
// }

// .mb-3,
// .my-3 {
//     margin-bottom: 1rem !important;
// }

// .ml-3,
// .mx-3 {
//     margin-left: 1rem !important;
// }

// .m-4 {
//     margin: 1.5rem !important;
// }

// .mt-4,
// .my-4 {
//     margin-top: 1.5rem !important;
// }

// .mr-4,
// .mx-4 {
//     margin-right: 1.5rem !important;
// }

// .mb-4,
// .my-4 {
//     margin-bottom: 1.5rem !important;
// }

// .ml-4,
// .mx-4 {
//     margin-left: 1.5rem !important;
// }

// .m-5 {
//     margin: 3rem !important;
// }

// .mt-5,
// .my-5 {
//     margin-top: 3rem !important;
// }

// .mt-6,
// .my-6 {
//     margin-top: 4rem !important;
// }

// .mr-5,
// .mx-5 {
//     margin-right: 3rem !important;
// }

// .mb-5,
// .my-5 {
//     margin-bottom: 3rem !important;
// }

// .ml-5,
// .mx-5 {
//     margin-left: 3rem !important;
// }

// .p-0 {
//     padding: 0 !important;
// }

// .pt-0,
// .py-0 {
//     padding-top: 0 !important;
// }

// .pr-0,
// .px-0 {
//     padding-right: 0 !important;
// }

// .pb-0,
// .py-0 {
//     padding-bottom: 0 !important;
// }

// .pl-0,
// .px-0 {
//     padding-left: 0 !important;
// }

// .p-1 {
//     padding: 0.25rem !important;
// }

// .pt-1,
// .py-1 {
//     padding-top: 0.25rem !important;
// }

// .pr-1,
// .px-1 {
//     padding-right: 0.25rem !important;
// }

// .pb-1,
// .py-1 {
//     padding-bottom: 0.25rem !important;
// }

// .pl-1,
// .px-1 {
//     padding-left: 0.25rem !important;
// }

// .p-2 {
//     padding: 0.5rem !important;
// }

// .pt-2,
// .py-2 {
//     padding-top: 0.5rem !important;
// }

// .pr-2,
// .px-2 {
//     padding-right: 0.5rem !important;
// }

// .pb-2,
// .py-2 {
//     padding-bottom: 0.5rem !important;
// }

// .pl-2,
// .px-2 {
//     padding-left: 0.5rem !important;
// }

// .p-3 {
//     padding: 1rem !important;
// }

// .pt-3,
// .py-3 {
//     padding-top: 1rem !important;
// }

// .pr-3,
// .px-3 {
//     padding-right: 1rem !important;
// }

// .pb-3,
// .py-3 {
//     padding-bottom: 1rem !important;
// }

// .pl-3,
// .px-3 {
//     padding-left: 1rem !important;
// }

// .p-4 {
//     padding: 1.5rem !important;
// }

// .pt-4,
// .py-4 {
//     padding-top: 1.5rem !important;
// }

// .pr-4,
// .px-4 {
//     padding-right: 1.5rem !important;
// }

// .pb-4,
// .py-4 {
//     padding-bottom: 1.5rem !important;
// }

// .pl-4,
// .px-4 {
//     padding-left: 1.5rem !important;
// }

// .p-5 {
//     padding: 3rem !important;
// }

// .pt-5,
// .py-5 {
//     padding-top: 3rem !important;
// }

// .pr-5,
// .px-5 {
//     padding-right: 3rem !important;
// }

// .pb-5,
// .py-5 {
//     padding-bottom: 3rem !important;
// }

// .pl-5,
// .px-5 {
//     padding-left: 3rem !important;
// }

// .m-n1 {
//     margin: -0.25rem !important;
// }

// .mt-n1,
// .my-n1 {
//     margin-top: -0.25rem !important;
// }

// .mr-n1,
// .mx-n1 {
//     margin-right: -0.25rem !important;
// }

// .mb-n1,
// .my-n1 {
//     margin-bottom: -0.25rem !important;
// }

// .ml-n1,
// .mx-n1 {
//     margin-left: -0.25rem !important;
// }

// .m-n2 {
//     margin: -0.5rem !important;
// }

// .mt-n2,
// .my-n2 {
//     margin-top: -0.5rem !important;
// }

// .mr-n2,
// .mx-n2 {
//     margin-right: -0.5rem !important;
// }

// .mb-n2,
// .my-n2 {
//     margin-bottom: -0.5rem !important;
// }

// .ml-n2,
// .mx-n2 {
//     margin-left: -0.5rem !important;
// }

// .m-n3 {
//     margin: -1rem !important;
// }

// .mt-n3,
// .my-n3 {
//     margin-top: -1rem !important;
// }

// .mr-n3,
// .mx-n3 {
//     margin-right: -1rem !important;
// }

// .mb-n3,
// .my-n3 {
//     margin-bottom: -1rem !important;
// }

// .ml-n3,
// .mx-n3 {
//     margin-left: -1rem !important;
// }

// .m-n4 {
//     margin: -1.5rem !important;
// }

// .mt-n4,
// .my-n4 {
//     margin-top: -1.5rem !important;
// }

// .mr-n4,
// .mx-n4 {
//     margin-right: -1.5rem !important;
// }

// .mb-n4,
// .my-n4 {
//     margin-bottom: -1.5rem !important;
// }

// .ml-n4,
// .mx-n4 {
//     margin-left: -1.5rem !important;
// }

// .m-n5 {
//     margin: -3rem !important;
// }

// .mt-n5,
// .my-n5 {
//     margin-top: -3rem !important;
// }

// .mr-n5,
// .mx-n5 {
//     margin-right: -3rem !important;
// }

// .mb-n5,
// .my-n5 {
//     margin-bottom: -3rem !important;
// }

// .ml-n5,
// .mx-n5 {
//     margin-left: -3rem !important;
// }

// .m-auto {
//     margin: auto !important;
// }

// .mt-auto,
// .my-auto {
//     margin-top: auto !important;
// }

// .mr-auto,
// .mx-auto {
//     margin-right: auto !important;
// }

// .mb-auto,
// .my-auto {
//     margin-bottom: auto !important;
// }

// .ml-auto,
// .mx-auto {
//     margin-left: auto !important;
// }

// .justify-content-start {
//     justify-content: flex-start !important;
// }

// .justify-content-end {
//     justify-content: end !important;
// }

// .justify-content-between {
//     justify-content: space-between !important;
// }

// .justify-content-evenly {
//     justify-content: space-evenly !important;
// }

// .justify-content-around {
//     justify-content: space-around !important;
// }

// .justify-content-center {
//     justify-content: center !important;
// }

// .justify-content-stretch {
//     justify-content: stretch !important;
// }

// .align-items-center {
//     align-items: center !important;
// }

// .align-items-start {
//     align-items: start !important;
// }

// .align-items-stretch {
//     align-items: stretch !important;
// }

// .flex-column {
//     flex-direction: column !important;
// }

// .flex-auto {
//     flex: auto !important;
// }

// .text-center {
//     text-align: center;
// }

// #logo {
//     color: $dark;
//     text-decoration: none;
//     transition: all 0.2s ease-in-out;
//     display: inline-block;

//     &:hover {
//         text-decoration: none;
//     }

//     svg {
//         display: inline-block;
//         height: auto;
//         width: 65px;
//         max-width: 100%;

//         * {
//             fill: $white;
//         }
//     }

//     img {
//         display: inline-block;
//         height: auto;
//         width: 45px;
//         max-width: 100%;
//     }
// }

// #nav.is-auth #logo svg * {
//     fill: $dark-green;
// }

// // Formating
// .page {
//     height: 100vh;
// }

// .padding-nav {
//     padding-top: 3rem;
// }

// // Font size
// .small {
//     font-size: 0.8rem;
// }

// .medium {
//     font-size: 1rem;
// }

// .big {
//     font-size: 1.2rem;
// }

// // Font weight
// .light {
//     font-weight: 100;
// }

// .normal {
//     font-weight: normal;
// }

// .bold {
//     font-weight: bold;
// }

// // Font style
// .italic {
//     font-style: italic;
// }

// .capitelize::first-letter {
//     text-transform: capitalize;
// }

// .capitelize-full {
//     text-transform: capitalize;
// }

// .uppercase {
//     text-transform: uppercase;
// }

// .lowercase {
//     text-transform: lowercase;
// }

// Colors
.white {
    color: $white;
}

.black {
    color: $black;
}

.dark-grey {
    color: $dark-grey;
}

.light-grey {
    color: $light-grey;
}

.grey {
    color: $grey;
}

.green {
    color: $green;
}

.dark-green {
    color: $dark-green;
}

.cyan {
    color: $cyan;
}

.red {
    color: $red;
}

.purple {
    color: $purple;
}

.dark-purple {
    color: $dark-purple;
}

.blue {
    color: $blue;
}

.dark-blue {
    color: $dark-blue;
}

.accent {
    color: $accent;
}

.primary {
    color: $primary;
}

.warn {
    color: $warn;
}

// Elements
.sep {
    display: block;
    width: 100%;
    height: 1px;
    background-color: $grey;
    opacity: 0.5;
}

button.mat-menu-item.nav-menu-item {
    font-weight: bold;
    font-size: 0.8rem;
    color: grey;
    transition: all 0.2s ease-in-out;

    mat-icon {
        color: grey !important;
    }

    span {
        transform: translateY(1px);
        display: inline-block;
    }

    &.deconnexion {
        background-color: rgba($light-red, 0);
        color: $red;

        mat-icon {
            color: $red !important;
        }

        &:hover {
            background-color: rgba($light-red, 0.2);
        }
    }
}

.card {
    background-color: white;
    border-radius: 8px;
}

//CK Editor

.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
    border: none !important;
    box-shadow: none !important;
}

.ck-editor__editable_inline:not(.ck-comment__input *) {
    @apply min-h-24;
    overflow-y: auto;
}

/* Dans le fichier SCSS du composant */
.ck.ck-reset_all {
    position: absolute !important;
    z-index: 1050;
    /* Valeur supérieure au z-index du modal */
}

.textAreaButtons {
    top: 0px !important;
}

.splitviewFullScreen {
    position: absolute;
    height: 100vh;
    width: 100%;
    z-index: 1000;
}