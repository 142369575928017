.mat-button-base {
  &.mat-grey {
    color: $dark-grey;
    background-color: rgba($grey, 0.5);
  }
  &.mat-primary {
    color: $grey;
    background-color: rgba($dark-green, 0.5);
  }
  &.mat-accent {
    color: $dark-green;
    background-color: rgba($accent, 0.5);
  }
  &.mat-warn {
    color: $red;
    background-color: rgba($red, 0.5);
  }
  &:disabled {
    filter: contrast(0.5) opacity(0.5);
  }
}
