$icomoon-font-family: "iconED" !default;
$icomoon-font-path: "fonts/ed" !default;

$icon-ed-chevron: "\e90a";
$icon-ed-text-plan-correction: "\e90b";
$icon-ed-file-redelivery: "\e900";
$icon-ed-hero-image: "\e901";
$icon-ed-new-account: "\e902";
$icon-ed-new-epson: "\e903";
$icon-ed-packshot: "\e904";
$icon-ed-pdf: "\e905";
$icon-ed-plan-correction: "\e906";
$icon-ed-text-correction: "\e907";
$icon-ed-corrections: "\e927";
$icon-ed-pdf-repository: "\e925";
$icon-ed-upload: "\e926";
$icon-ed-visuals-isolation: "\e924";
