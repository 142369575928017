@font-face {
    font-family: 'icon';
    src: url('fonts/icon.eot?8xogmx');
    src: url('fonts/icon.eot?8xogmx#iefix') format('embedded-opentype'),
        url('fonts/icon.ttf?8xogmx') format('truetype'),
        url('fonts/icon.woff?8xogmx') format('woff'),
        url('fonts/icon.svg?8xogmx#icon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-add-language:before {
    content: "\e900";
}

.icon-packz:before {
    line-height: 2.5rem;
    font-size: 2.5rem;
    content: "\e92b";
}

.icon-icons_compare:before {
    content: "\e91b";
}

.icon-icons_compare1:before {
    content: "\e91c";
}

.icon-icons_kiosk:before {
    content: "\e91a";
}

.icon-add-master-project:before {
    content: "\e908";
}

.icon-add-child-project:before {
    content: "\e909";
}

.icon-add-custom-field:before {
    content: "\e90c";
}

.icon-add-form:before {
    content: "\e90d";
}

.icon-add-group:before {
    content: "\e90e";
}

.icon-add-printer-source:before {
    content: "\e90f";
}

.icon-add-printer:before {
    content: "\e910";
}

.icon-add-user:before {
    content: "\e911";
}

.icon-adress:before {
    content: "\e912";
}

.icon-approve-project:before {
    content: "\e913";
}

.icon-brief-project:before {
    content: "\e914";
}

.icon-colorbooks:before {
    content: "\e915";
}

.icon-edit-form:before {
    content: "\e916";
}

.icon-edit-project:before {
    content: "\e917";
}

.icon-linkedin:before {
    content: "\e918";
}

.icon-mail:before {
    content: "\e919";
}

.icon-phone:before {
    content: "\e91d";
}

.icon-prefill:before {
    content: "\e91e";
}

.icon-reject-project:before {
    content: "\e91f";
}

.icon-reset-fields:before {
    content: "\e920";
}

.icon-revisions:before {
    content: "\e921";
}

.icon-save:before {
    content: "\e922";
}

.icon-send:before {
    content: "\e923";
}

.icon-splitview:before {
    content: "\e928";
}

.icon-table-project:before {
    content: "\e929";
}

.icon-valid:before {
    content: "\e92a";
}