
// TO KEEP
.form-row {
  @apply flex w-full justify-between pr-40;

  mat-form-field, app-autocomplete-filter {
      @apply w-[45%] items-center;
  }
}

.separator {
  @apply border-t-[1px] border-gray-300 h-[1px];
}

.form-subtitle{
  @apply text-2xl font-bold text-tw-primary;

}